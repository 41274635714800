<template>
  <div id="homeButton" class="dark:text-white inline flex self-center my-2">
    <router-link to="/" class="noeffect">
      <svg
        class="w-8 h-8 md:w-8 md:h-8"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HomeButton.vue",
  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped></style>
