import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import HeadingOne from "./components/typography/HeadingOne.vue";
import VueMoment from "vue-moment";
import "./assets/tailwind.css";
import "./assets/tailwind.css";

window.hljs = require("highlight.js");

Vue.config.productionTip = false;
Vue.component("HeadingOne", HeadingOne);
Vue.use(VueMoment);

new Vue({
  router,
  store,
  watch: {
    $route(to) {
      document.title = (to.meta.title || "Patrick Junod") + " | Patrick Junod";
    },
  },
  render: (h) => h(App),
}).$mount("#app");
