<template>
  <div id="app" class="relative">
    <NavBar id="navbar" />
    <div
      class="container w-full h-screen md:max-w-4xl mx-auto pt-20 px-4 md:px-10"
    >
      <div
        class="home flex flex-col align-middle justify-center text-center content-center mb-4"
      >
        <router-view :key="$route.fullPath" />
      </div>
      <FooterComponent />
    </div>
    <div class="fixed -z-10 w-screen h-screen overflow-hidden inset-0">
      <div class="noisy inset-0 absolute"></div>
      <div
        id="gradient-bg-1"
        class="w-[1300px] h-[1300px] absolute"
        style="opacity: 0"
      ></div>
      <div
        id="gradient-bg-2"
        class="w-[1300px] h-[1300px] absolute"
        style="opacity: 0"
      ></div>
      <div
        id="gradient-bg-3"
        class="w-[1300px] h-[1300px] absolute"
        style="opacity: 0"
      ></div>
      <div
        id="gradient-bg-4"
        class="w-[1300px] h-[1300px] absolute"
        style="opacity: 0"
      ></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

import NavBar from "@/components/NavBar";
import FooterComponent from "@/components/FooterComponent";

export default {
  components: { NavBar, FooterComponent },
  metaInfo() {
    return {
      title: "Patrick Junod | Développeur Fullstack à Neuchâtel",
      meta: [
        {
          hid: "application-name",
          name: "application-name",
          content: "Patrick Junod",
        },
        {
          hid: "apple-mobile-web-app-title",
          name: "apple-mobile-web-app-title",
          content: "Patrick Junod",
        },
        { hid: "og:locale", name: "og:locale", content: "ch_FR" },
        { hid: "theme-color", name: "theme-color", content: "#ffffff" },
        {
          hid: "msapplication-TileColor",
          name: "msapplication-TileColor",
          content: "#da532c",
        },
        {
          hid: "viewport",
          name: "viewport",
          content: "width=device-width,initial-scale=1.0",
        },
        {
          hid: "og:site_name",
          property: "og:site_name",
          content: "Patrick Junod",
        },
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "og:author", property: "og:author", content: "Patrick Junod" },
        {
          hid: "og:url",
          property: "og:url",
          content: "https://www.patrickjunod.dev" + this.$route.fullPath,
        },
      ],
    };
  },
  mounted() {
    const showAnim = gsap
      .from("#navbar", {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: "top top",
      end: 99999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });

    let tl = gsap.timeline();
    tl.to(
      ["#gradient-bg-1", "#gradient-bg-2", "#gradient-bg-3", "#gradient-bg-4"],
      {
        opacity: 0,
        x: "random(-50, 50)vw",
        y: "random(-50, 50)vh",
      }
    );
    tl.to(
      ["#gradient-bg-1", "#gradient-bg-2", "#gradient-bg-3", "#gradient-bg-4"],
      {
        stagger: "random(0,0.5)",
        opacity: 1,
        duration: 2,
      }
    );
    tl.to(
      ["#gradient-bg-1", "#gradient-bg-2", "#gradient-bg-3", "#gradient-bg-4"],
      {
        x: "random(-50, 50)vw",
        y: "random(-50, 50)vh",
        delay: "random(0,1)",
        ease: "power0",
        stagger: "random(0,0.5)",
        duration: "random(6,12)",
        repeat: -1,
        repeatRefresh: true,
      }
    );
  },
};
</script>
