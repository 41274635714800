<template>
  <nav
    id="header"
    class="fixed flex justify-between w-full z-50 top-0 justify-items-center items-center px-8"
  >
    <Transition name="slide-fade">
      <router-link
        to="/"
        class="noeffect text-lg font-bold dark:text-white hover:text-gray-200 inline-flex items-center hover:scale-105 duration-200 transform"
        v-if="$route.name != 'Accueil'"
      >
        <img
          src="https://pbs.twimg.com/profile_images/1593265495989489665/kPLybffK_400x400.jpg"
          class="w-8 h-8 rounded-full mr-2"
          alt="Photo de profil de Patrick Junod"
        />
        <span> Patrick Junod </span>
      </router-link>
    </Transition>
    <span v-if="$route.name == 'Accueil'"></span>
    <div class="inline-flex">
      <Transition name="slide-fade">
        <HomeButton
          v-show="$route.name != 'Accueil'"
          class="inline mr-2 hover:scale-105 duration-200 transform"
        />
      </Transition>
      <a
        class="noeffect inline mr-2 flex self-center my-2 hover:scale-105 duration-200 transform"
        href="mailto:contact@patrickjunod.dev"
      >
        <svg
          class="w-8 h-8 md:w-8 md:h-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </a>
      <darkmode class="inline hover:scale-105 duration-200 transform" />
    </div>
  </nav>
</template>

<script>
import Darkmode from "@/components/Darkmode.vue";
import HomeButton from "@/components/HomeButton.vue";

export default {
  components: { HomeButton, Darkmode },
};
</script>
