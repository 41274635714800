<template>
  <Transition name="slide-fade">
    <div id="footer" class="flex justify-center w-full cursor-default">
      <div
        class="w-auto text-xs ml-5 md:ml-0 px-2 md:px-0 py-1 opacity-40 hover:opacity-100 transform duration-200"
      >
        <span
          >Patrick Junod - {{ new Date().getFullYear() }} | Blog propulsé
          par</span
        >
        <a
          class="dark:text-white noeffect"
          href="https://buttercms.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            class="inline-flex"
            src="https://cdn.buttercms.com/JSSDbrHPSnGlLUcyHTn5"
            width="125"
            alt="ButterCMS Logo"
          />
        </a>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {};
</script>
