<template>
  <div class="dark:text-white inline flex self-center my-2">
    <button @click="setDarkMode(!darkMode)" aria-label="Dark mode">
      <svg
        v-if="darkMode"
        class="w-8 h-8 md:w-8 md:h-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
      <svg
        v-if="!darkMode"
        class="w-8 h-8 md:w-8 md:h-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "dark-mode",
  data: function () {
    return {
      darkMode: "",
    };
  },
  methods: {
    setDarkMode: function () {
      this.darkMode = !this.darkMode;
      this.darkMode
        ? document.querySelector("html").classList.add("dark")
        : document.querySelector("html").classList.remove("dark");
      this.darkMode
        ? (localStorage.theme = "dark")
        : (localStorage.theme = "light");
    },
  },
  mounted() {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
    this.darkMode
      ? document.querySelector("html").classList.add("dark")
      : document.querySelector("html").classList.remove("dark");
  },
};
</script>

<style scoped></style>
