<template>
  <h1 :class="classes + ' ' + size + ' ' + color">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: "heading-one",
  props: {
    classes: {
      type: String,
      default: "font-medium font-sans -ml-1 mt-6 mb-2",
    },
    size: {
      type: String,
      default: "text-4xl",
    },
    color: {
      type: String,
      default: "dark:text-gray-100 text-gray-700",
    },
  },
};
</script>

<style scoped></style>
